import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import {
  ServiceLocation,
  ServicePayment,
  ServiceType,
  StaffMember,
} from '@wix/bookings-uou-types';
import {
  Location as SlotLocation,
  LocationType,
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { CatalogData } from '../../api/types';
import {
  ActionLabels,
  GetServiceResponse,
  Header as FormHeader,
  PaymentOptions,
  Rate,
  Schedule,
  ScheduleStatus,
} from '@wix/ambassador-services-catalog-server/http';
import {
  mapServiceLocations,
  mapServicePayment,
} from '@wix/bookings-uou-mappers';
import { FormView, Submission } from '@wix/forms-ui/types';
import { createFormView } from './service-form.mapper';
import { TFunction } from '../../types/types';
import { isOfferedAsPricingPlanOnly } from '../payment/payment';
import { SelectedPaymentOption } from '@wix/ambassador-bookings-gateway/types';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import { BookingsLineItemOption } from '@wix/bookings-checkout-api';

export type Location = {
  id?: string;
  name?: string;
  address?: string;
  locationType: LocationType;
};

export type Service = {
  id: string;
  name: string;
  rate: Rate;
  staffMembers: StaffMember[];
  location: Location;
  isPendingApprovalFlow: boolean;
  isWaitingListFlow: boolean;
  paymentTypes: SelectedPaymentOption[];
  type: ServiceType;
  scheduleId: string;
  formSchema: FormView;
  formHeader: FormHeader;
  totalNumberOfSessions: number;
  payment: ServicePayment;
  videoConferenceProviderId?: string;
  actionLabels: ActionLabels;
  maxNumberOfParticipants: number;
  maxNumberOfParticipantsWithoutPP?: number;
};

const getOccupiedSpotsForSlotFromCart = ({
  slot,
  scheduleId,
  bookingsLineItemOptions,
}: {
  slot?: Slot;
  scheduleId?: string;
  bookingsLineItemOptions: BookingsLineItemOption[];
}) => {
  return bookingsLineItemOptions.reduce((acc, next) => {
    if (next.sessionId === slot?.sessionId || next.scheduleId === scheduleId) {
      return acc + (next.numberOfParticipants || 0);
    }
    return acc;
  }, 0);
};

export const mapCatalogServiceToService = ({
  serviceId,
  catalogData,
  slotAvailability,
  t,
  pricingPlanDetails,
  memberships,
  numberOfSessions,
  preFilledValues,
  isDynamicPriceEnabled = true,
  isFixFormUoUCheckboxLinkEnabled,
  isAlwaysShowComplexPhoneFieldEnabled,
  bookingsLineItemOptions,
}: {
  serviceId: string;
  catalogData: CatalogData;
  slotAvailability: SlotAvailability;
  t: TFunction;
  memberships?: ListEligibleMembershipsResponse;
  pricingPlanDetails?: PaidPlans;
  numberOfSessions: number;
  preFilledValues?: Submission;
  isDynamicPriceEnabled?: boolean;
  isFixFormUoUCheckboxLinkEnabled?: boolean;
  isAlwaysShowComplexPhoneFieldEnabled?: boolean;
  bookingsLineItemOptions?: BookingsLineItemOption[];
}): Service => {
  const activeSchedule = getActiveSchedule(catalogData.service);
  const scheduleId = activeSchedule!.id!;

  const serviceType = getServiceType(activeSchedule);

  const staffMembers = catalogData.staffMembers.map((staffMember) => {
    return {
      id: staffMember.id!,
      name: staffMember.name,
    };
  });

  const slotLocation: Maybe<SlotLocation> = slotAvailability?.slot?.location;
  const serviceLocation: ServiceLocation = mapServiceLocations(
    activeSchedule,
  )[0];

  const isCourse = serviceType === ServiceType.COURSE;

  const location = isCourse
    ? mapServiceLocationToLocation(serviceLocation)
    : mapSlotLocationToLocation(slotLocation);

  const paymentOptions: PaymentOptions = catalogData.service.service
    ?.paymentOptions!;
  const paymentTypes: SelectedPaymentOption[] = mapPaymentOptionsToPaymentTypes(
    paymentOptions,
    pricingPlanDetails,
    memberships,
  );

  const payment = mapServicePayment(catalogData.service);
  const { isVariedPricing } = payment.paymentDetails;
  const isDynamicPrice = isDynamicPriceEnabled && isVariedPricing;

  const occupiedSpotsFromCart = bookingsLineItemOptions
    ? getOccupiedSpotsForSlotFromCart({
        scheduleId,
        slot: slotAvailability.slot,
        bookingsLineItemOptions,
      })
    : 0;

  const availableSpots = Math.max(
    0,
    (slotAvailability.openSpots ?? 0) - occupiedSpotsFromCart,
  );
  const maxParticipantsPerBooking =
    catalogData?.service?.service?.policy?.maxParticipantsPerBooking ?? 0;

  const maxNumberOfParticipants = getMaxNumberOfParticipants({
    availableSpots,
    maxParticipantsPerBooking,
    pricingPlanDetails,
    memberships,
  });

  const maxNumberOfParticipantsWithoutPP = getMaxNumberOfParticipants({
    availableSpots,
    maxParticipantsPerBooking,
  });

  const formSchema = createFormView({
    catalogData,
    availability: slotAvailability,
    preFilledValues,
    isDynamicPrice,
    isFixFormUoUCheckboxLinkEnabled,
    maxNumberOfParticipants,
    isAlwaysShowComplexPhoneFieldEnabled,
    ...(isOfferedAsPricingPlanOnly(payment) && {
      ...(memberships ? { memberships } : { pricingPlanDetails }),
    }),
    t,
  });

  const rate = activeSchedule.rate!;

  const formHeader = catalogData.service.form?.header!;

  const isPendingApprovalFlow = !!catalogData.service.service?.policy
    ?.bookingsApprovalPolicy?.isBusinessApprovalRequired;

  return {
    id: serviceId,
    name: catalogData.service.service!.info!.name!,
    formHeader,
    rate,
    payment,
    type: serviceType,
    staffMembers,
    paymentTypes,
    location,
    totalNumberOfSessions: numberOfSessions,
    isPendingApprovalFlow,
    isWaitingListFlow: !!catalogData.service.service?.policy?.waitingListPolicy
      ?.isEnabled,
    videoConferenceProviderId: activeSchedule?.conferenceProvider?.providerId,
    scheduleId,
    formSchema,
    actionLabels: catalogData.service.form!.actionLabels!,
    maxNumberOfParticipants,
    ...(isDynamicPriceEnabled
      ? {
          maxNumberOfParticipantsWithoutPP,
        }
      : {}),
  };
};

export const getActiveSchedule = (service: GetServiceResponse): Schedule => {
  return (
    service?.schedules?.find(
      (schedule) => schedule.status === ScheduleStatus.CREATED,
    ) || service?.schedules?.[0]!
  );
};

export const getServiceType = (schedule: Schedule): ServiceType => {
  return (
    (schedule?.tags?.find(
      (tag: string) =>
        tag === ServiceType.COURSE ||
        tag === ServiceType.GROUP ||
        tag === ServiceType.INDIVIDUAL,
    ) as ServiceType) || ServiceType.INDIVIDUAL
  );
};

const mapPaymentOptionsToPaymentTypes = (
  paymentOptions: PaymentOptions,
  pricingPlanDetails?: PaidPlans,
  memberships?: ListEligibleMembershipsResponse,
): SelectedPaymentOption[] => {
  const paymentTypes = [];
  if (paymentOptions?.wixPayOnline) {
    paymentTypes.push(SelectedPaymentOption.ONLINE);
  }
  if (paymentOptions?.wixPayInPerson) {
    paymentTypes.push(SelectedPaymentOption.OFFLINE);
  }
  if (
    (memberships?.eligibleMemberships?.length ||
      pricingPlanDetails?.plans?.length) &&
    paymentOptions?.wixPaidPlan
  ) {
    paymentTypes.push(SelectedPaymentOption.MEMBERSHIP);
  }
  return paymentTypes;
};

const mapServiceLocationToLocation = (location: ServiceLocation): Location => {
  return {
    id: location.businessLocation?.id,
    name: location.businessLocation?.name,
    ...{
      address:
        location?.locationText ??
        location?.businessLocation?.address?.formattedAddress,
    },
    locationType: location?.type as any,
  };
};

const mapSlotLocationToLocation = (location?: SlotLocation): Location => {
  return {
    id: location?.id,
    name: location?.name,
    address: location?.formattedAddress,
    locationType: location!.locationType!,
  };
};

const getMaxNumberOfParticipants = ({
  availableSpots,
  maxParticipantsPerBooking,
  pricingPlanDetails,
  memberships,
}: {
  maxParticipantsPerBooking: number;
  availableSpots: number;
  pricingPlanDetails?: PaidPlans;
  memberships?: ListEligibleMembershipsResponse;
}) => {
  const remainingCredits: Maybe<number[]> = memberships
    ? memberships?.eligibleMemberships
        ?.filter((membership) => membership?.credits?.remaining)
        .map((membership) => membership!.credits!.remaining!)
    : pricingPlanDetails?.plans
        ?.filter((plan) => plan.creditRemain)
        .map((plan) => plan.creditRemain!);

  const maxRemainingCredits = remainingCredits?.length
    ? Math.max(...remainingCredits!)
    : maxParticipantsPerBooking;

  return Math.min(
    maxParticipantsPerBooking,
    availableSpots,
    maxRemainingCredits,
  );
};
