import { CartFlow } from '../../../../types/types';
import { CreateActionParams } from '../actions';

export type OnCartCTAClick = (cartFlow?: CartFlow) => Promise<void>;

export function createOnCartCTAClickAction({
  context,
  getControllerState,
}: CreateActionParams): OnCartCTAClick {
  return async (cartFlow?: CartFlow) => {
    const { wixSdkAdapter } = context;
    if (cartFlow === CartFlow.ADD_TO_CART) {
      await wixSdkAdapter.navigateAfterAddToCart();
    } else if (cartFlow === CartFlow.CHECKOUT) {
      await wixSdkAdapter.navigateToEcomCart();
    } else {
      await wixSdkAdapter.navigateToServiceListWithQuery('', '');
    }
  };
}
